import Panel from "./Panel";

export default function About(props) {
    return(
        <div className={`about window shadow ${props.toggle ? 'toggled' : 'untoggled'}`}>
            <Panel header="About" />
            <div className="content">
                <p>
                Welcome to {process.env.REACT_APP_APP_NAME}, a Web App built to create dynamic Spotify playlists.

                </p>
                <br/>
                <p>
                All changes are only saved to the Spotify playlists created by this web app.
                </p>
            </div>
            <div className="content">
                Made With Coffee and Love by <a href="https://www.mikalyoung.com/" target="_blank" rel="noreferrer">Mikal Young</a>
            </div>

        </div>
    )
}