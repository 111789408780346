import axios from 'axios';
import genreTree from '../genreTree.json'
import generalGenres from '../categorized-subset.json'
    // calculate the new playlist, remove the songs that are no longer there
    // return 
    // eg edm = [do or die, el chapo, rescue me]
    // adtr = [rescue me, made of wax]
    // childish = [do or die, redbone]
    // removing edm should leave rescue me and do of die
    // but the user removed el chapo, that should be gone too




export function calculate (contentArray){
    let artists = getArtists(contentArray)
    let genres = getGenres(contentArray)
    let prePlaylist = searchLikedSongs(artists, genres)
    return prePlaylist.map(({ track }) => track.uri)

}

export async function setSongs(prePlaylist, playlistID) {
    if (prePlaylist.length === 0) {
        return ''
    }
    let temp = prePlaylist.splice(0, Math.min(100, prePlaylist.length))

    let payload = {
        'uris': temp//.map(({ track }) => track.uri)
    }
    await axios.post(`https://api.spotify.com/v1/playlists/${playlistID}/tracks`, payload)
    return await setSongs(prePlaylist, playlistID)
}

export async function removeSongs(prePlaylist, playlistID) {
    if (prePlaylist.length === 0) {
        return ''
    }
    let temp = prePlaylist.splice(0, Math.min(100, prePlaylist.length))
    let payload = {
        'tracks': temp.map((uri) => { return { uri }})
    }
    await axios.delete(`https://api.spotify.com/v1/playlists/${playlistID}/tracks`, { data: payload })
    return await removeSongs(prePlaylist, playlistID)
}

export function intersect(a, b) {
    return a.filter(Set.prototype.has, new Set(b));
}

export function difference(a, b) {
    let setB = new Set(b)
    const _difference = new Set(a);
    for (const elem of setB) {
        _difference.delete(elem);
    }
    return [..._difference];
}

function searchLikedSongs(artistArray, genresArray) {
    let savedTracks = JSON.parse(localStorage.getItem('savedTracks'))
    let allGenres = JSON.parse(localStorage.getItem('allGenres'))
    //const start = performance.now();
    let genreArtists = genresArray.flatMap((genre) => {
        return findGenres(genre, allGenres)
    }) 
    //console.log(genreArtists)
    //console.log(artistArray)

    artistArray = [...new Set([...artistArray, ...genreArtists])]
    //console.log(artistArray)
    let songs = savedTracks.filter(({ track }) => {
        return artistArray.some((artist) => {
            for (let index = 0; index < track.artists.length; index++) {
                let songArtist = track.artists[index];
                if (songArtist.name === artist) {
                    return true
                }
            }
            return false
        })

    })
    //const end = performance.now();
    //console.log(`Execution time: ${end - start} ms`);
    return songs
}

function findGenres(genre, allGenres = {}) {
    let artistsToGet = []

    /*if(!genreTree[genre] && !generalGenres[genre]) {
        let genresArray = JSON.parse(localStorage.getItem(`genreArray`)) || []
        autocorrect({words: genresArray})
        let didYouMean = autocorrect(genre) 
        console.log(didYouMean)
        genre = didYouMean || genre
    }*/

    if(genre === 'edm') {
        genre = 'electronic'
    }

    if(generalGenres[genre]){
        artistsToGet = generalGenres[genre].flatMap((genre) => {
            return getSubGenres(genre)
        })
    } 
    
    //console.log(genreTree[genre])
    
    //if(genreTree[genre]) {
    artistsToGet = getSubGenres(genre).concat(artistsToGet)
    //}
    //console.log(artistsToGet)
    //console.log(genre)
    function getSubGenres(genre){
        let subgenres = genreTree[genre]?.children
        let currentGenre = allGenres[genre] || []
        if(!subgenres){
            return currentGenre
        }
        if(subgenres.length === 0){
            return currentGenre
        }
        return subgenres.flatMap((childGenre) => {
            return getSubGenres(childGenre)
        }).concat(currentGenre)
        
    }
    artistsToGet = [...new Set([...artistsToGet])].sort()
    return artistsToGet
}

function getArtists(content) {
    return content.flatMap((element) => {
        if (element.type === 'Artist'){
            if(element.isCustom){
                let artistArray = JSON.parse(localStorage.getItem('ArtistArray')) || []
                let query = element.value.toLowerCase().trim()
                return artistArray.find((artist) =>{
                    return artist.toLowerCase().search(query) === 0
                }) || []
            } 
            return element.value
        }
        else
            return []
    })
}
function getGenres(content) {
    return content.flatMap((element) => {
        if (element.type === 'Genre')
            return [element.value]
        else
            return []
    })
}

/*let functions = { 
    intersect,
    difference,
    setSongs,
    removeSongs,
    calculate
}
export default functions*/