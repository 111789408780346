import '../css/Welcome.scss'
export default function Welcome(props) {
    let baseUrl = process.env.REACT_APP_API_URL

    return (
        <>
            <h1 className='welcome'>
                <span>Welcome, </span><span>please log in</span>
            </h1>
            <div>
                <a className="login-holder grn-btn" href={baseUrl + '/login'}>
                    Connect To
                    <img src={require("../shared/Spotify_Logo_RGB_Black.png")} alt="OPEN SPOTIFY" />
                </a>
            </div>

        </>
    )
}