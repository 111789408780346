import Cross from "../shared/Cross";
import { useNavigate } from 'react-router-dom';
import { usePromiseTracker } from "react-promise-tracker";
import { useEffect, useRef } from "react";


export default function Navbar(props) {
    let navigate = useNavigate()
    const { promiseInProgress } = usePromiseTracker({ delay: 1000 });
    let { toggle, userData } = props
    let lock =  useRef(false)
    useEffect(() => {
        if(lock.current === false && !localStorage.getItem("savedTracks")){
            setTimeout(() => {
                //props.setToggle(true)
            }, 1500)
        }
        lock.current = true
    })

    return (
        <div className="nav-bar">
            <div>
                <h1
                    onClick={() => {
                        navigate('/', { replace: true })
                    }}
                >
                    {/*
                        Playlist XP*
                        Just4Me
                        JUST/4/ME
                    */}
                    {process.env.REACT_APP_APP_NAME}
                </h1>
            </div>
            <div className="banner">Updates Coming Soon!</div>
            {
                <div className="toggle" onClick={() => props.setToggle(!toggle)}>
                    {userData === false && (toggle
                        ? <div className="open" >
                            <Cross size="24px" action="" />
                        </div>

                        : <div className="closed" >
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>)
                    }
                    {userData && userData?.images && userData.images[0]?.url &&
                        <div className="loading-holder">
                            <div className={"profile-pic "}>
                                <img src={userData.images[0]?.url} alt={userData.display_name} />
                            </div>
                            <div className={`border ${promiseInProgress ? 'loading' : 'not-loading'}`}>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}