import leven from 'leven'

const autocorrect = function(options) {
    options || (options = {})
    let dictionary = options.words || []
    let len = dictionary.length
  
    return function(str) {
      let distance, bestWord, i, word, min
  
      for (i = 0; i < len; i++) {
        word = dictionary[i]
        distance = leven(str.toLowerCase(), word.toLowerCase())
  
        if (distance === 0) {
          return word
        } else if (min === undefined || distance < min) {
          min = distance
          bestWord = word
        }
      }
  
      return bestWord
    }
  }
  
export default autocorrect