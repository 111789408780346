import Cross from "../shared/Cross";

export default function PlaylistContent(props) {
    let { content } = props
    return (
        <div className="playlist-contents">
            {content.map((element) => {
                return (
                    <div key={element.id} className="playlist-item">
                        <div className="">
                            <img className="spotify-logo" src={require("../shared/Spotify_Icon_RGB_Black.png")} alt={`Open ${element.value} in Spotify`}/>
                            <div className="type">{element.type}:</div>
                            <div className="value">{element.value}</div>
                        </div>

                        <Cross click={() => props.removeContent({target: element})} />
                    </div>
                )
            })
            }
        </div>
    )
}
