export default function Playlist(props) {
    let { playlist } = props
    return (
        <div
            key={playlist.id}
            className="playlist"
            onClick={() => {
                window.open(playlist.spotifyPlaylistObject.external_urls.spotify, '_blank')
            }}
        >
            <div className="metaRow">
                <h4>
                    Open in
                </h4>
                <div className="spotify-logo">
                    <img src={require("../shared/Spotify_Icon_RGB_Black.png")} alt="Spotify Logo"/>
                </div>
            </div>
            <h2 id={playlist.id}>
                {playlist.title}
            </h2>
            <button
                className="flt-btn"
                onClick={(event) => {
                    event.stopPropagation()
                    props.edit(playlist)
                }}
            >
                Edit
            </button>
        </div>
    )
}