
import About from "./About";
import Panel from "./Panel";
import Playlists from './Playlists';
import Welcome from './Welcome'

export default function Dashboard(props) {
  let { userData, toggle } = props

  return (
    <div className="dashboard">
      <About userData={userData} toggle={toggle} />
      <div className="main window shadow">
        <Panel
          header={userData ? "Playlists" : "Welcome"}
          close={
            userData ?
              () => {
                localStorage.removeItem('savedTracks')
                localStorage.removeItem('GenreArray')
                localStorage.removeItem('ArtistArray')
                localStorage.removeItem('allGenres')
                props.logout()
              } : null
          }
          tooltip='logout :('
        />
        {userData && <Playlists userData={userData} />}
        {userData === false && <Welcome />}
      </div>
    </div>
  );
}