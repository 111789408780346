import axios from 'axios';

async function getTracks(type = 'savedTracks', next=undefined, myMax = -1) {

    let typeUrl
    if (type === 'savedTracks'){
        typeUrl = 'https://api.spotify.com/v1/me/tracks'
    }
    else {
        typeUrl = `https://api.spotify.com/v1/playlists/${type}/tracks`
    }

    if (next === undefined) {
        // if type is missing, its a playlist
        next = typeUrl + '?offset=0&limit=50'
    }

    if (next !== null && myMax !== 0) {
        let { data } = await axios.get(next)
        let { total, items } = data

        if (myMax === -1 && total > 50) {
            let chunkArray = []
            let myMax = Math.ceil((total / 50) / 6)
            for (let index = 0; index < Math.min(6, Math.ceil(total/50)); index++) {
                let url = `${typeUrl}?offset=${index * (myMax * 50)}&limit=50`
                chunkArray.push(getTracks(type, url, myMax))
            }
            return await Promise.all(chunkArray).then(arrays => arrays.flat())
        }

        //removes an unnecessary array
        items.forEach(({ track }) => {
            delete track.available_markets
            delete track.album?.available_markets
        })


        return [...items, ...await getTracks(type, data.next, --myMax,)]
    } else {
        return []
    }
}

export default getTracks