
import Panel from './Panel';
import { useState, useEffect, useRef } from 'react';
import autocorrect from '../shared/functions/autocorrect';
export default function Input(props) {
    let type = props.type
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestion, setSuggestion] = useState('')
    const [typeArray] = useState(JSON.parse(localStorage.getItem(`${type}Array`)) || [])
    const [trueValue, setTrueValue] = useState('')
    const [focused, setFocused] = useState(true)

    const realInput = useRef(0)
    const fakeInput = useRef(0)

    function submit() {
        if (searchQuery.length > 0) {
            props.addContent({
                target: {
                    type,
                    value: trueValue.toLowerCase() === searchQuery.toLowerCase() ? autocorrect({ words: typeArray })(searchQuery) : searchQuery.toLowerCase().trim(),
                    id: Date.now(),
                    isCustom: trueValue.toLowerCase() !== searchQuery.toLowerCase()
                }
            })
            setSearchQuery('')
            setTrueValue('')
        }
        //props.close()
    }

    useEffect(() => {
        function findSuggestion() {
            let query = escapeRegex(searchQuery.toLowerCase())
            return typeArray.find((text) => {
                let index = text.toLowerCase().search(query)
                return index === 0
            }) || ''
        }
        function escapeRegex(string) {
            return string.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        }
        if (searchQuery.length > 1) {
            let temp = findSuggestion()
            if(process.env.REACT_APP_API_URL){
                console.log(temp)
            }
            if(type === 'Genre' && temp[0]){
                temp = temp[0].toUpperCase() + temp.slice(1)
            }
            setTrueValue(temp)
            setSuggestion(searchQuery + (temp?.substr(searchQuery.length) || ''))

        } else {
            setSuggestion(searchQuery)
        }
    }, [searchQuery, typeArray, type])

    return (
        <div className='input window shadow'>
            <Panel header={'New ' + props.type} close={props.close} />
            <div
                className='input-container'
            >
                <div className='autoCompleteTextBox'>
                    <div className='input-holder'>
                        <input
                            className="real-input"
                            value={searchQuery}
                            onChange={(event) => {
                                let temp = event.target.value
                                setSearchQuery(temp)
                            }}
                            type="text"
                            ref={realInput}
                            autoFocus
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    submit()
                                }
                                if (e.key === "Tab") {
                                    setSearchQuery(trueValue)
                                }


                                //document.querySelector('.fake-input')[0].scrollLeft = realInput.current.scrollLeft
                            }}
                            //onKeyUp={}
                            onClick={() => {
                                if (focused && searchQuery.length > 1 && trueValue.length > 1) {
                                    setSearchQuery(trueValue)
                                }
                                setFocused(true)
                            }}
                            // onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            onScroll={() => {
                                fakeInput.current.scrollLeft = realInput.current.scrollLeft
                            }}
                        />
                        <input
                            type="text"
                            placeholder={suggestion}
                            disabled={true}
                            value={suggestion}
                            ref={fakeInput}
                            className='fake-input'
                        >
                            {/*suggestion*/}
                        </input>
                    </div>
                </div>

                <button
                    className="grn-btn"
                    onClick={() => {
                        submit()
                    }}
                >
                    Add
                </button>
            </div>
        </div>
    )
}