
import Cross from '../shared/Cross';
export default function Panel(props) {
    let header = props.header
    let setTitle = props.setTitle || null
    let close = props.close || null
    // if the close prop exists, then the cross is rendered

    return(
        <div className='panel'>
            {!setTitle &&
                <div className={'panel-header title ' + header}>
                    {header}
                </div>
            }
            {setTitle &&
            <div className='panel-header title input-container'>
                <input 
                    defaultValue={props.title}
                    autoFocus ={ props.title === 'Untitled' }
                    onBlur={(e)=>{
                        setTitle(e.target.value)
                    }}
                >
                </input>
            </div> 
            }
            {close &&
                <div className='close' title={props.tooltip || ''} onClick={() => close()}>
                    <Cross/>
                </div>
            }
        </div>
    )
}