import './css/App.scss';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import axios from "axios";

import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useSearchParams, Route, Routes } from "react-router-dom";

function App() {

  const [toggle, setToggle] = useState(false)
  const [searchParams] = useSearchParams();
  const [userData, setUserData] = useState(null)
  const [headers, setHeaders] = useState(null)
  const expiration = useRef(JSON.parse(localStorage.getItem('authData'))?.exp)
  const isMounted = useRef(false)
  let navigate = useNavigate()
  let baseUrl = process.env.REACT_APP_API_URL

  const logout = useCallback( function () {
    localStorage.removeItem('authData')
    setHeaders(null)
    setUserData(false)
    navigate('/', { replace: true })
  },[navigate])

  const getAuthData = useCallback(async ({ code, refresh_token }) => {
    expiration.current = null

    if(!code && !refresh_token){
      logout()
      return
    }

    let { data } = await axios.get(baseUrl + '/getToken', { params: { code, refresh_token } })

    if (data.access_token) {
      data.exp = Date.now() + (data.expires_in - 120) * 1000 // "expires" 2 mins early
      expiration.current = data.exp
      let tempHeader = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + data.access_token,
        "Accept": 'application/json'
      }
      setHeaders(tempHeader)
      data.headers = tempHeader

      localStorage.setItem('authData', JSON.stringify(data))
    } else {
      //the token might be dead or not
      // either way the user will have to logout 
      logout()
    }

    return data
  }, [baseUrl, logout])



  useEffect(() => {
    let authData = null
    try {
      authData = JSON.parse(localStorage.getItem('authData'))
      //console.log(authData)
    } catch (error) {
      localStorage.removeItem('authData')
    }


    if (searchParams.get('code')) {
      let code = searchParams.get('code')
      getAuthData({ code })
    } else if (authData?.exp > Date.now()) {
      expiration.current = authData.exp
      setHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authData.access_token,
        "Accept": 'application/json'
      })
    } else {
      setUserData(false)
      return
    }

    if (!isMounted.current){
      axios.interceptors.response.use((data) => {
        if (expiration.current && expiration.current < Date.now()) {
          let authData = JSON.parse(localStorage.getItem('authData'))
          console.log('refreshing token ahead of time')
          getAuthData(authData)
        }
        return data
      }, (error) => {
        // if anything goes wrong, check auth
        let authData = JSON.parse(localStorage.getItem('authData'))
        if(baseUrl){
          console.log(error.response)
        }
        if(error.response.status === 401){
          getAuthData(authData).then((newData)=>{
            if(newData.access_token){
              error.config.headers = newData.headers 
              return new axios.request(error.config)
            }
          })
        } else {
          return Promise.reject(error)
        }
      })
    }
    isMounted.current = true
  }, [searchParams, getAuthData, baseUrl])

  useEffect(() => {
    if (headers) {
      axios.defaults.headers = headers
      getUser(headers).then(({ data }) => {
        if (data) {
          setUserData(data)
          if (searchParams.get('code')) {
            navigate('/', { replace: true })
          }
        }

      })
    }
  }, [headers, navigate, searchParams])


  async function getUser(headers) {
    let data = await axios.get('https://api.spotify.com/v1/me', {
      headers
    })
    return data
  }

  return (
    <div className="App">
      <Navbar 
        setToggle={setToggle} 
        toggle={toggle} 
        userData={userData}
      />
      <Routes>
        {['/', '/:sub'].map(path =>
          <Route
            key={path}
            path={path}
            element={
              <Dashboard
                toggle={toggle}
                userData={userData}
                logout={logout}
              />
            } 
          />
        )}
      </Routes>
    </div>
  );
}

export default App;
